@keyframes zoomOutAndI-animation {
    0% {transform:scale3d(0.78, 0.78, 0.78)}
    10% {transform:scale3d(0.69, 0.69, 0.69)}
    80% {transform:scale3d(0.69, 0.69, 0.69)}
    100% {transform:scale3d(0.78, 0.78, 0.78)}
}


.zoomOutAndIn {
    animation-name: zoomOutAndI-animation;
}