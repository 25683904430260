.rhap_progress-section {
  width: 100%;
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  grid-template-rows: 1;
  column-gap: 4px;
  align-items: center;
  margin: 2px 0px;
}
.rhap_progress-container {
  margin: 0px;
  text-align: center;
  width: 100%;
  height: 4px;
  background-color: rgba(0, 0, 0, 0.2);
}
.rhap_progress-bar {
  background-color: inherit;
}
.rhap_progress-indicator {
  background-color: black;
  width: 10px;
  height: 10px;
  top: -3px;
  margin-left: 0px;
}
.rhap_progress-filled {
  background-color: black;
}
.rhap_download-progress {
  background-color: inherit;
}
.rhap_time {
  font-size: 11px;
  justify-self: center;
}
.rhap_progress-filled {
  background-color: inherit;
}
